import './StatusBanner.scss'
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export function StatusBanner(props: { possible: boolean }) {
    const orderPossible = (
        <div className="status-banner">
            <div className="status-content">
                <div className="possible-container">
                    <FaCheckCircle id='icon-green'/>
                    <p className='status-text-green'>Bestellung aktuell möglich</p>
                </div>
                <div className="next-container">
                    <p className='status-text-white'>Nächste Fleischausgabe:</p>
                    <p className='status-text-green'>17. April 2025</p>
                </div>
            </div>
        </div>)

    const orderImpossible = (
        <div className="status-banner">
            <div className="status-content">
                <div className="possible-container">
                    <FaTimesCircle id='icon-red'/>
                    <p className='status-text-red'>Bestellung aktuell nicht möglich</p>
                </div>
                <div className="next-container">
                    <p className='status-text-white'>Nächste Bestellphase:</p>
                    <p className='status-text-red'>November 2023</p>
                </div>
            </div>
        </div>)

    return (
        <>
            {props.possible ? orderPossible : orderImpossible}
        </>
    )
}